.users__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto";
  padding: 10px;
}

.users__title {
  font-family: "Roboto";
  font-weight: bold;
  color: rgba(255, 255, 255, 0.905);
  font-size: 4rem;
  margin-top: 50px;
}

.users__grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, minmax(150px, 2fr));
  grid-template-rows: repeat(2, minmax(150px, 2fr));
}

.user__title {
  font-size: 3rem;
  font-weight: bold;
}

.user__card {
  background: rgba(255, 255, 255, 0.905);
  border-radius: 15px;
  padding: 10px;
}

.user__link > button {
  outline: none;
  border: none;
  color: rgba(255, 255, 255, 0.905);
  background: rgba(27, 27, 41, 0.8);
  border-radius: 8px;
  padding: 5px 10px;
  transition: 0.3s;
}

.user__link > button:hover {
  color: rgba(255, 255, 255, 0.905);
  background: rgba(27, 27, 41, 1);
}
