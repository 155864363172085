@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

#root {
  background: rgb(27, 27, 41);
  background: -moz-linear-gradient(
    0deg,
    rgba(27, 27, 41, 1) 0%,
    rgba(53, 53, 64, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(27, 27, 41, 1) 0%,
    rgba(53, 53, 64, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(27, 27, 41, 1) 0%,
    rgba(53, 53, 64, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1b1b29",endColorstr="#353540",GradientType=1);
  height: 100vh;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
