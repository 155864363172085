.home__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto";
}

.home__title {
  font-family: "Roboto";
  font-weight: bold;
  color: rgba(255, 255, 255, 0.905);
  font-size: 4rem;
  margin-top: 50px;
}

.home__description {
  font-family: "Roboto";
  color: rgba(255, 255, 255, 0.621);
  font-size: 16px;
  margin: 0 50px;
  text-align: justify;
}

.home__buttons {
  margin-top: 150px;
  display: flex;
  gap: 40px;
}

.home__button {
  outline: none;
  border: none;
  font-size: 18px;
  line-height: 1.7;
  border-radius: 15px;
  color: rgb(27, 27, 41);
  background: rgba(255, 255, 255, 0.905);
  transition: 0.3s;
  padding: 10px 30px;
}

.home__button:hover {
  color: rgba(255, 255, 255, 0.621);
  background: rgba(27, 27, 41, 0.8);
  transition: 0.3s;
}
