.ustocks__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Roboto";
  padding: 10px;
}

.ustocks__title {
  font-family: "Roboto";
  font-weight: bold;
  color: rgba(255, 255, 255, 0.905);
  font-size: 4rem;
  margin-top: 50px;
}

.ustocks__description {
  font-family: "Roboto";
  color: rgba(255, 255, 255, 0.621);
  font-size: 16px;
  margin: 0 50px;
  text-align: justify;
}
